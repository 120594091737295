import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';

export type JWTToken = string | undefined;

interface AxiosPromiseWithCancel<T> extends AxiosPromise<T> {
    cancel: () => void;
}

export const getToken = (): JWTToken => process.env.REACT_APP_JWT_TOKEN;

const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
});

const REQUEST_DEFAULT_HEADERS: Record<string, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: 'application/ld+json',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
};

const REQUEST_DEFAULT_OPTIONS: AxiosRequestConfig = {
    method: 'GET',
    headers: {},
};

const normalizeUri = (prefix: string, id?: string | null): string =>
    id && id.length ? `/${prefix.replace('/', '')}/${id.replace(`/${prefix.replace('/', '')}/`, '')}` : '';

const requestApi = <Entity = unknown>(options: AxiosRequestConfig): AxiosPromiseWithCancel<Entity> => {
    const cancelToken = Axios.CancelToken.source();
    const token = getToken();
    options = {
        ...REQUEST_DEFAULT_OPTIONS,
        ...options,
        cancelToken: cancelToken.token,
        url: options.url?.replace('//', '/'),
        headers: { ...REQUEST_DEFAULT_HEADERS, ...options.headers },
        paramsSerializer: (params): string => qs.stringify(params),
    };
    if (token && token.length > 0) {
        options.headers.Authorization = `Bearer ${token}`;
    }

    if (typeof options.data === 'object') {
        const data = { ...options.data };
        Object.keys(data).forEach((key) => {
            data[key] = data[key] === '' ? null : data[key];
        });

        options.data = data;
    }

    const promise = axios.request(options) as AxiosPromiseWithCancel<Entity>;
    promise.cancel = (): void => {
        cancelToken.cancel();
    };

    return promise;
};

export { axios, requestApi, normalizeUri };
